import React from "react";

export const ContentsIcon = () => (
  <svg
    width="20"
    height="20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-macaw-ui-candidate
  >
    <path
      d="M2.499 17.5c-.459 0-.851-.163-1.178-.49a1.602 1.602 0 0 1-.489-1.177v-10c0-.236.08-.434.24-.594.16-.16.358-.24.593-.239.236 0 .435.08.595.24.16.16.24.358.239.593v10h13.333c.236 0 .434.08.594.24.16.16.24.358.24.594s-.08.434-.24.594a.803.803 0 0 1-.594.24H2.499Zm3.333-3.333c-.458 0-.85-.164-1.177-.49a1.602 1.602 0 0 1-.49-1.177V3.333c0-.458.164-.85.49-1.177.327-.327.72-.49 1.177-.49h3.48a1.649 1.649 0 0 1 1.166.48l1.187 1.187H17.5c.458 0 .85.164 1.177.49.327.327.49.72.49 1.177v7.5c0 .458-.164.85-.49 1.178-.327.326-.72.49-1.177.489H5.832Zm0-1.667h11.667V5h-6.521L9.31 3.333H5.832V12.5Zm5.208-2.917-.958-1.25a.396.396 0 0 0-.333-.166.396.396 0 0 0-.334.166L8.02 10.167c-.112.139-.13.284-.053.437.076.153.205.23.386.23h6.625c.18 0 .309-.077.385-.23a.38.38 0 0 0-.052-.437l-2.02-2.646a.396.396 0 0 0-.334-.167.396.396 0 0 0-.333.167L11.04 9.583Z"
      fill="currentColor"
    />
  </svg>
);
