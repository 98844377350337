import React from "react";

export const TranslationsIcon = () => (
  <svg
    width="20"
    height="20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-macaw-ui-candidate
  >
    <path
      d="m12.582 15.792-.73 2.02a.772.772 0 0 1-.29.376.782.782 0 0 1-.46.145.81.81 0 0 1-.676-.344.734.734 0 0 1-.094-.76l3.167-8.375a.91.91 0 0 1 .312-.375.782.782 0 0 1 .459-.146h.625c.166 0 .319.049.458.146a.91.91 0 0 1 .312.375l3.167 8.396a.713.713 0 0 1-.083.74.782.782 0 0 1-.667.343.731.731 0 0 1-.468-.156 1.006 1.006 0 0 1-.303-.406l-.687-1.98h-4.042Zm.5-1.459h3l-1.458-4.125h-.084l-1.458 4.125Zm-5.583-2.666L3.915 15.25a.79.79 0 0 1-.583.23.79.79 0 0 1-.583-.23.79.79 0 0 1-.23-.583.79.79 0 0 1 .23-.584l3.625-3.625a13.378 13.378 0 0 1-1.386-1.812 13.281 13.281 0 0 1-1.031-1.98h1.75c.25.5.518.952.803 1.355.285.403.628.826 1.03 1.27A11.583 11.583 0 0 0 9.061 7.24c.403-.701.743-1.448 1.021-2.239H1.665a.807.807 0 0 1-.594-.24.803.803 0 0 1-.239-.593c0-.236.08-.434.24-.594.16-.16.358-.24.593-.24h5V2.5c0-.236.08-.434.24-.594.16-.16.358-.24.594-.24s.434.08.594.24c.16.16.24.359.239.594v.833h5c.236 0 .434.08.594.24.16.16.24.358.24.594s-.08.434-.24.594a.803.803 0 0 1-.594.24h-1.583a15.13 15.13 0 0 1-1.188 2.874c-.5.93-1.118 1.806-1.854 2.625l2 2.042-.625 1.708-2.583-2.583Z"
      fill="currentColor"
    />
  </svg>
);
