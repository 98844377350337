import React from "react";

export const DiscountsIcon = () => (
  <svg
    width="20"
    height="20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-macaw-ui-candidate
  >
    <path
      d="M6.249 9.167a2.81 2.81 0 0 1-2.063-.855 2.81 2.81 0 0 1-.854-2.062c0-.806.285-1.493.854-2.063a2.81 2.81 0 0 1 2.063-.854 2.81 2.81 0 0 1 2.062.854c.57.57.854 1.257.854 2.063a2.81 2.81 0 0 1-.854 2.062 2.81 2.81 0 0 1-2.062.855Zm0-1.667c.347 0 .642-.122.886-.365.243-.243.364-.538.364-.885s-.122-.643-.365-.886A1.202 1.202 0 0 0 6.249 5c-.348 0-.643.122-.886.365a1.202 1.202 0 0 0-.364.885c0 .347.121.642.365.886.243.243.538.364.885.364Zm7.5 9.167a2.81 2.81 0 0 1-2.063-.855 2.81 2.81 0 0 1-.854-2.062c0-.806.285-1.493.854-2.063a2.81 2.81 0 0 1 2.063-.854 2.81 2.81 0 0 1 2.062.854c.57.57.854 1.257.854 2.063a2.81 2.81 0 0 1-.854 2.062 2.81 2.81 0 0 1-2.062.855Zm0-1.667c.347 0 .642-.122.885-.365.244-.243.365-.538.365-.885s-.122-.643-.365-.886a1.203 1.203 0 0 0-.885-.364c-.348 0-.643.122-.886.365a1.202 1.202 0 0 0-.364.885c0 .347.121.642.365.886.243.243.538.365.885.364Zm-9.834 1.083a.79.79 0 0 1-.229-.583.79.79 0 0 1 .23-.583l11-11a.79.79 0 0 1 .583-.23.79.79 0 0 1 .583.23.79.79 0 0 1 .23.583.79.79 0 0 1-.23.583l-11 11a.79.79 0 0 1-.583.23.79.79 0 0 1-.584-.23Z"
      fill="currentColor"
    />
  </svg>
);
