import React from "react";

export const CustomersIcon = () => (
  <svg
    width="20"
    height="20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-macaw-ui-candidate
  >
    <path
      d="M9.999 10a3.21 3.21 0 0 1-2.354-.98 3.21 3.21 0 0 1-.98-2.353c0-.917.327-1.702.98-2.355a3.21 3.21 0 0 1 2.354-.979 3.21 3.21 0 0 1 2.354.98 3.21 3.21 0 0 1 .979 2.354 3.21 3.21 0 0 1-.98 2.354A3.21 3.21 0 0 1 10 10Zm5 6.667h-10c-.459 0-.851-.164-1.178-.49A1.602 1.602 0 0 1 3.332 15v-.667c0-.472.122-.906.365-1.302s.566-.698.968-.906c.861-.43 1.736-.754 2.625-.97A11.417 11.417 0 0 1 10 10.834c.916 0 1.82.108 2.708.324.889.215 1.764.538 2.625.968.403.208.726.51.97.907.242.396.364.83.363 1.301V15c0 .458-.163.85-.49 1.177-.326.327-.719.49-1.176.49ZM4.999 15h10v-.667a.816.816 0 0 0-.417-.708 10.844 10.844 0 0 0-2.27-.843 9.652 9.652 0 0 0-4.625 0c-.765.187-1.522.469-2.272.843a.815.815 0 0 0-.302.292.776.776 0 0 0-.114.416V15Zm5-6.667c.458 0 .85-.163 1.177-.49.327-.326.49-.719.49-1.176 0-.459-.164-.851-.49-1.178A1.602 1.602 0 0 0 9.998 5c-.459 0-.851.164-1.178.49-.326.328-.49.72-.489 1.178 0 .458.163.85.49 1.177.327.327.719.49 1.177.49Z"
      fill="currentColor"
    />
  </svg>
);
